import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

/* Import Assets*/
import IndexIcon480pxPng from "~assets/images/new-icons/icon-index-480px.png"
import IndexIcon320pxPng from "~assets/images/new-icons/icon-index-320px.png"
import UpcomingIcon480pxPng from "~assets/images/new-icons/icon-upcoming-480px.png"
import UpcomingIcon320pxPng from "~assets/images/new-icons/icon-upcoming-320px.png"
import TraceIcon480pxPng from "~assets/images/new-icons/icon-trace-480px.png"
import TraceIcon320pxPng from "~assets/images/new-icons/icon-trace-320px.png"
import TemporaryDwellingsIcon480pxPng from "~assets/images/new-icons/icon-temporary-dwellings-480px.png"
import TemporaryDwellingsIcon320pxPng from "~assets/images/new-icons/icon-temporary-dwellings-320px.png"
import AboutIcon320pxPng from "~assets/images/new-icons/icon-about-320px.png"
import AboutIcon480pxPng from "~assets/images/new-icons/icon-about-480px.png"

/* Import Local Style(s) */
import "./header.css"

const Header = ({ location }) => {
  const {
    allSanityProject: {
      totalCount
    }
  } = useStaticQuery(graphql`
    query UpcomingProjectsCountQuery {
      allSanityProject(filter: {upcomingProject: {eq: true}}) {
        totalCount
      }
    }
  `)

  let isProjectsPage = false
  if (location){
    isProjectsPage = location.pathname === "/projects/" || location.pathname === "/projects"
  }

  return (
    <div className="header">
      <Link className="logo" to={isProjectsPage ? "/" : "/projects"}>
        <p>The Portal</p>
      </Link>
      <div className="icons">
        <Link to="/projects">
          <div className="icon-wrapper">
            <img
              className="icon desktop-only"
              src={IndexIcon480pxPng}
              alt=""
            />
            <img
              className="icon mobile-only"
              src={IndexIcon320pxPng}
              alt=""
            />            
          </div>
        </Link>
        <Link to="/trace">
          <div className="icon-wrapper">
            <img
              className="icon desktop-only"
              src={TraceIcon480pxPng}
              alt=""
            />
            <img
              className="icon mobile-only"
              src={TraceIcon320pxPng}
              alt=""
            />            
          </div>
        </Link>
        <a
          href="https://temporarydwellings.theportal.place"
          className="temporary-dwellings"
        >
          <div className="icon-wrapper">
          <img
              className="icon desktop-only"
              src={TemporaryDwellingsIcon480pxPng}
              alt=""
            />
            <img
              className="icon mobile-only"
              src={TemporaryDwellingsIcon320pxPng}
              alt=""
            />
          </div>
        </a>
        <Link to="/about" partiallyActive={true} activeClassName="active">
          <div className="icon-wrapper">
            <img
              className="icon desktop-only"
              src={AboutIcon480pxPng}
              alt=""
            />
            <img
              className="icon mobile-only"
              src={AboutIcon320pxPng}
              alt=""
            />            
          </div>
        </Link>
        {totalCount > 0 && 
          <Link to="/upcoming-projects">
            <div className="icon-wrapper mobile-only">
              <img
                className="icon rotate"
                src={UpcomingIcon320pxPng}
                alt=""
              />
            </div>
          </Link>
        }
      </div>
      {totalCount > 0 && 
        <div className="icons desktop-only">
          <Link to="/upcoming-projects">
            <div className="icon-wrapper">
              <img
                className="icon rotate"
                src={UpcomingIcon480pxPng}
                alt=""
              />
            </div>
          </Link>
        </div>
      }
    </div>
  )
}

export default Header
