// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-components-upcoming-projects-upcoming-projects-js": () => import("./../../../src/pages/projects/components/upcomingProjects/upcomingProjects.js" /* webpackChunkName: "component---src-pages-projects-components-upcoming-projects-upcoming-projects-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-trace-index-js": () => import("./../../../src/pages/trace/index.js" /* webpackChunkName: "component---src-pages-trace-index-js" */),
  "component---src-pages-upcoming-projects-index-js": () => import("./../../../src/pages/upcoming-projects/index.js" /* webpackChunkName: "component---src-pages-upcoming-projects-index-js" */),
  "component---src-templates-format-index-js": () => import("./../../../src/templates/format/index.js" /* webpackChunkName: "component---src-templates-format-index-js" */),
  "component---src-templates-keyword-index-js": () => import("./../../../src/templates/keyword/index.js" /* webpackChunkName: "component---src-templates-keyword-index-js" */),
  "component---src-templates-part-of-index-js": () => import("./../../../src/templates/partOf/index.js" /* webpackChunkName: "component---src-templates-part-of-index-js" */),
  "component---src-templates-practice-index-js": () => import("./../../../src/templates/practice/index.js" /* webpackChunkName: "component---src-templates-practice-index-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */)
}

