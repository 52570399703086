import React, { useState, useEffect } from "react"

// Import Global Context(s)
import ProjectsSorting from "~context/projectsSorting"

const ProjectsSortingProvider = ({ children }) => {
  const [sortBy, setSortBy] = useState(() => {
    let sortBy
    if (typeof window !== "undefined") {
      sortBy = window.localStorage.getItem("sortBy")
    }
    return sortBy !== null ? sortBy : "date"
  })
  const [isAscending, setIsAscending] = useState(() => {
    let isAscending
    if (typeof window !== "undefined") {
      isAscending = window.localStorage.getItem("isAscending") === "true"
    }
    return isAscending !== null ? isAscending : false
  })

  useEffect(() => {
    localStorage.setItem("sortBy", sortBy)
  }, [sortBy])

  useEffect(() => {
    localStorage.setItem("isAscending", isAscending)
  }, [isAscending])

  return (
    <ProjectsSorting.Provider
      value={{
        sortingState: [sortBy, setSortBy],
        ascendingState: [isAscending, setIsAscending],
      }}
    >
      {children}
    </ProjectsSorting.Provider>
  )
}

export default ProjectsSortingProvider
