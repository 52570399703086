import React, { useState, useEffect } from "react"

// Import Global Context(s)
import ProjectsFilter from "~context/projectsFilter"

const ProjectsFilterProvider = ({ children }) => {
  const [filter, setFilter] = useState()

  useEffect(() => {
    const filter = localStorage.getItem("filter")
    if (filter) {
      setFilter(filter)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("filter", filter)
  }, [filter])

  return (
    <ProjectsFilter.Provider value={[filter, setFilter]}>
      {children}
    </ProjectsFilter.Provider>
  )
}

export default ProjectsFilterProvider
