import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import slug from "slug"

// Import Global Context(s)
import ProjectsSorting from "~context/projectsSorting"
import ProjectsContext from "~context/projects"

const sortProjects = (projects, sortBy, ascending) => {
  const projectsClone = [...projects]

  projectsClone.sort((a, b) => {
    // handle practice which uses a 'reference' rather than data directly on project
    if (sortBy === "practice") {
      if (!a.practiceReference.title) {
        a.practiceReference.title = ""
      }
      if (!b.practiceReference.title) {
        b.practiceReference.title = ""
      }
      if (a.practiceReference.title === b.practiceReference.title) {
        return 0
      }
      if (
        a.practiceReference.title.toLowerCase() >
        b.practiceReference.title.toLowerCase()
      ) {
        return 1
      } else {
        return -1
      }
    } else {
      if (!a[sortBy]) {
        a[sortBy] = ""
      }
      if (!b[sortBy]) {
        b[sortBy] = ""
      }
      if (a[sortBy] === b[sortBy]) {
        return 0
      }
      if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
        return 1
      } else {
        return -1
      }
    }
  })
  if (!ascending) {
    projectsClone.reverse()
  }
  return projectsClone
}

// const filterProjects = (projects, filter) => {
//   const projectsClone = [...projects]
//   return projectsClone
// }

const generateProjectPathName = (title, site) => {
  if (!title) {
    console.log("Missing title", title)
  }
  if (!site) {
    console.log("Missing site", site)
  }
  return `/p/${slug(title, { lower: true })}-${slug(site, {
    lower: true,
  })}`
}

const getCurrentProjectIndex = (pathname, projects) => {
  return projects.findIndex(sortedProject => {
    return (
      generateProjectPathName(sortedProject.title, sortedProject.site) ===
      pathname
    )
  })
}

const getNextProjectPathname = (currentProjectIndex, projects) => {
  // get 'next' project
  let nextProject
  if (projects[currentProjectIndex + 1]) {
    nextProject = projects[currentProjectIndex + 1]
  } else {
    nextProject = projects[0]
  }
  return generateProjectPathName(nextProject.title, nextProject.site)
}

const getPreviousProjectPathname = (currentProjectIndex, projects) => {
  // get 'previous' project
  let previousProject
  if (projects[currentProjectIndex - 1]) {
    previousProject = projects[currentProjectIndex - 1]
  } else {
    previousProject = projects[projects.length - 1]
  }
  return generateProjectPathName(previousProject.title, previousProject.site)
}

const ProjectsProvider = ({ children, location }) => {
  const {
    allSanityProject: { nodes },
  } = useStaticQuery(graphql`
    query ProjectsProviderQuery {
      allSanityProject(
        filter: { displayOnSite: { eq: true } }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          _id
          title
          artists
          format
          site
          date
          keywords {
            title
          }
          partOf {
            title
          }
          practiceReference {
            title
            color {
              hex
            }
            desaturatedColor {
              hex
            }
          }
          displayOnSite
          upcomingProject
        }
      }
    }
  `)

  const [sortBy] = useContext(ProjectsSorting).sortingState
  const [isAscending] = useContext(ProjectsSorting).ascendingState
  const [projects, setProjects] = useState(nodes)

  let currentProjectIndex, nextProjectPathname, previousProjectPathname
  if (location.pathname.includes("/p/")) {
    currentProjectIndex = getCurrentProjectIndex(location.pathname, projects)
    nextProjectPathname = getNextProjectPathname(currentProjectIndex, projects)
    previousProjectPathname = getPreviousProjectPathname(
      currentProjectIndex,
      projects
    )
  } else {
    currentProjectIndex = undefined
    nextProjectPathname = undefined
    previousProjectPathname = undefined
  }

  useEffect(() => {
    setProjects(projects => sortProjects(projects, sortBy, isAscending))
  }, [sortBy, isAscending])

  return (
    <ProjectsContext.Provider
      value={[projects, nextProjectPathname, previousProjectPathname]}
    >
      {children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsProvider
