import React, { useRef } from "react"
import useInterval from "use-interval"
import { useEffectOnce } from "react-use"

/* Import Global Font(s) */
import "~assets/fonts/standard-book/standard-book.css"

/* Import Global Normalize */
import "normalize.css"

/* Import Global Reset */
import "./reset.css"

/* Import Global CSS Variable(s) */
import "./variables.css"

/* Import Global Style(s) */
import "./global.css"

/* Import Global Providers(s) */
import ProjectsFilterProvider from "~providers/projectsFilter"
import ProjectsSortingProvider from "~providers/projectsSorting"
import ProjectsProvider from "~providers/projects"

/* Import Global Component(s) */
import LoadingOverlay from "~components/loadingOverlay/loadingOverlay"
import Header from "~components/header/header"
// import TransitionRouter from "~components/transitionRouter/transitionRouter"

export default ({ location, children, pageContext }) => {
  // background colors to change between
  const backgroundColors = [
    "--color-blue-final-0",
    "--color-blue-final-1"
  ]

  // currently selected background
  const currentBackgroundColorsIndex = useRef(0)

  // alternate between first and second color options
  const toggleBackgroundColor = () => {
    currentBackgroundColorsIndex.current = currentBackgroundColorsIndex.current === 0 ? 1 : 0
    if (document) {
      document.documentElement.style.setProperty(
        "--color-background",
        `var(${backgroundColors[currentBackgroundColorsIndex.current]})`
      )
    }
  }

  // trigger initial color transition
  useEffectOnce(() => {
    if(window) {
      window.setTimeout(() => {
        toggleBackgroundColor()
      }, 10)
    }
  })

  // update new value every 12 seconds
  useInterval(() => {
    toggleBackgroundColor()
  }, 12000)

  if (pageContext.layout === "portal") {
    return <ProjectsSortingProvider>
      <ProjectsFilterProvider>
        <ProjectsProvider location={location}>
          <LoadingOverlay />
          {children}
        </ProjectsProvider>
      </ProjectsFilterProvider>
    </ProjectsSortingProvider>
  }

  return (
    <ProjectsSortingProvider>
      <ProjectsFilterProvider>
        <ProjectsProvider location={location}>
          <LoadingOverlay />
          <Header location={location} />
          {children}
        </ProjectsProvider>
      </ProjectsFilterProvider>
    </ProjectsSortingProvider>
  )
}
