import React, { useState, useEffect } from "react"
import classnames from "classnames"

// Import Local Style(s)
import "./loadingOverlay.css"

const LoadingOverlay = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      if (window) {
        window.setTimeout(() => {
          setIsLoaded(true)
        }, 1)
      }
    }
  }, [isLoaded, setIsLoaded])

  return (
    <div
      className={classnames("loading-overlay", {
        loaded: isLoaded,
      })}
    />
  )
}

export default LoadingOverlay
